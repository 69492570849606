import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function mapStateToProps(state, myProps) {
    let AMOUNT = 0;
    if (typeof state.CART[myProps.ID] !== "undefined") {
        AMOUNT = state.CART[myProps.ID];
    }
    return {
        PRODUCTS: state.PRODUCTS,
        AMOUNT: AMOUNT,
        FRIENDS_DISCOUNT: state.FRIENDS_DISCOUNT,
        USER: state.USER,
        PRICE_TYPE: state.PRICE_TYPE
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class ProductCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ID: props.ID,
            PRODUCT: this.props.PRODUCTS[parseInt(props.ID)],
            MANIPULATE: true
        }

        this.PRODUCT = this.props.PRODUCTS[parseInt(props.ID)];
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {

        }
    }

    renderBadges() {
        let LEFT_ONES = [];

        if (this.state.PRODUCT.eco == 1) {
            let BADGE = (
                <div className="angle-label" key="1">
                    <img src="/system_images/icons/leaf.svg" />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.ng == 1) {
            let BADGE = (
                <div className="angle-label" key="2">
                    <img src="/system_images/icons/no_bottle.svg" />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.no_grain == 1) {
            let BADGE = (
                <div className="angle-label" key="3">
                    <img src="/system_images/icons/no_grain.svg" />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.halal == 1) {
            let BADGE = (
                <div className="angle-label" key="4">
                    <img src="/system_images/icons/halal.svg" title="Ð¥Ð°Ð»ÑÐ»ÑŒ" />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.nobox == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src="/system_images/icons/no_box.svg" />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }


        if (LEFT_ONES.length) {
            return (
                <div className="tags">
                    { LEFT_ONES.map(badge => { return badge }) }
                </div>
            );
        } else {
            return false;
        }
    }

    renderMinusButton() {
        return (
            <a className="minus" onClick={ () => this.minusProduct() } tabIndex="0">
                <img src="/system_images/minus.svg" />
            </a>
        );
    }

    minusProduct() {
        this.props.minusCart(this.PRODUCT);
    }

    checkMaximum() {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    plusProduct() {
        this.launchManipulation();
        let check = this.checkMaximum();

        if (check) {
            this.props.plusCart(this.PRODUCT);
        }
    }

    renderCartedPrice(price_type = false) {
        if (price_type) {
            let USE_PRICE = this.PRODUCT['price' + price_type];
            if (this.props.AMOUNT > 0) {
                return USE_PRICE * this.props.AMOUNT;
            } else {
                return USE_PRICE;
            }
        }

        if (this.props.FRIENDS_DISCOUNT) {
            let PRICE = this.PRODUCT.price - (Math.round(this.PRODUCT.price / 100 * 25));
            if (this.props.AMOUNT > 0) {
                return PRICE * this.props.AMOUNT;
            } else {
                return PRICE;
            }
        } else {
            if (this.props.AMOUNT > 0) {
                return this.PRODUCT.price * this.props.AMOUNT;
            } else {
                return this.PRODUCT.price;
            }
        }
    }

    addToCart() {
        this.launchManipulation();
        this.props.addToCart(this.PRODUCT);
    }

    doPress(id) {
        this.setState({ PRESSED: id });
    }

    doUnpress() {
        this.setState({ PRESSED: false });
    }

    morePressed() {
        this.launchManipulation();
        let check = this.checkMaximum();

        if (check) {
            this.props.plusCart(this.PRODUCT);
        }
    }

    checkMaximum() {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    renderCartButton = () => {
        if (this.props.AMOUNT == 0) {
            return (
                <a className="to-cart-btn" onClick={ () => this.addToCart() } >
                    <img src="/system_images/cart.png" />
                </a>
            );
        } else {
            return this.renderManipulator();
        }
    }

    renderThePrice = () => {
        if ((this.PRODUCT.old_price > 0) && (this.PRODUCT.sale == 1)) {
            return (
                <>
                    <div className="price_holder">
                        <span className="price sale">{ this.renderCartedPrice() } Ñ€</span>
                        <span className="price old_price">{ this.renderDiscountPrice() } Ñ€</span>

                    </div>
                    { this.props.USER && this.props.USER.price_type > 0 || this.props.PRICE_TYPE ? (
                        <span className="your_price">Ð’Ð°ÑˆÐ° Ñ†ÐµÐ½Ð°: <strong>{ this.renderCartedPrice(this.props.PRICE_TYPE ? this.props.PRICE_TYPE : this.props.USER.price_type) } Ñ€</strong></span>
                    ) : null }
                </>
            )
        } else {
            return (<>
                <span className="price">{ this.renderCartedPrice() } Ñ€</span>
                { this.props.USER && this.props.USER.price_type > 0 || this.props.PRICE_TYPE ? (
                    <span className="your_price">Ð’Ð°ÑˆÐ° Ñ†ÐµÐ½Ð°: <strong>{ this.renderCartedPrice(this.props.PRICE_TYPE ? this.props.PRICE_TYPE : this.props.USER.price_type) } Ñ€</strong></span>
                ) : null }
            </>)
        }

    }

    renderDiscountPrice = () => {



        let OLD_PRICE = 0;
        if (this.props.AMOUNT > 0) {
            OLD_PRICE = this.PRODUCT.old_price * this.props.AMOUNT;
        } else {
            OLD_PRICE = this.PRODUCT.old_price;
        }

        return OLD_PRICE;

    }

    renderButtons = () => {
        if (this.state.PRODUCT.availableones > 0) {
            return (
                <div
                    className="button-holder"
                >

                    { this.renderThePrice() }
                    <div className="tocart">
                        { this.renderCartButton() }
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="button-holder"
                >
                </div>
            );
        }
    }

    renderPlusButton() {
        return (
            <a className="plus" onClick={ () => this.plusProduct() } tabIndex="0">
                <img src="/system_images/plus.svg" />
            </a>
        );
    }

    rmFixed(numb) {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderShowAmount() {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;
        if (this.props.AMOUNT > 0) {
            if (PRODUCT.peramount % 1 !== 0) {
                SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
            } else {
                SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
            }
        } else {
            SHOW_AMOUNT = PRODUCT.peramount;
        }

        return SHOW_AMOUNT;
    }

    renderCurShowAmount() {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if (this.props.AMOUNT < 1) {
            return PRODUCT.peramount;
        }

        if (PRODUCT.peramount % 1 !== 0) {
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderMaximumMessage() {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT == PRODUCT.availableones) {
            return (
                <div>
                    <div className="cart-max">Ð­Ñ‚Ð¾ Ð¼Ð°ÐºÑÐ¸Ð¼Ð°Ð»ÑŒÐ½Ð¾Ðµ ÐºÐ¾Ð»Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾ Ñ‚Ð¾Ð²Ð°Ñ€Ð°.</div>
                </div>
            );
        }
    }

    launchManipulation() {
        if (!this.state.MANIPULATE) {
            this.setState({ MANIPULATE: true });
        }
    }

    renderManipulator = () => {
        if (!this.state.MANIPULATE) {
            return false;
        }

        return (
            <div className="amount_controller">

                { this.renderMaximumMessage() }

                <div className="the_controls">
                    { this.renderMinusButton() }

                    <div className="the_amount">
                        <span className="current_amount">{ this.renderShowAmount() }</span>
                    </div>

                    { this.renderPlusButton() }
                </div>
            </div>
        );
    }

    renderMoreBlock() {
        if (WIDTH > 760) {
            return (
                <div className="read-some-more">
                    <span>
                        ÐŸÐ¾Ð´Ñ€Ð¾Ð±Ð½ÐµÐµ
                    </span>
                </div>
            );
        }
    }

    renderImage() {
        let SRC = this.state.PRODUCT.big;
        let SRC2 = this.state.PRODUCT.big_image;
        if ((typeof SRC !== "undefined") && (SRC !== "")) {
            let STYLE = { 'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + SRC };
            let STYLE2 = { 'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + SRC };
            if (SRC2 && (typeof SRC2 !== "undefined") && (SRC2 !== "")) {
                STYLE2 = { 'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + SRC2 };
            }

            return (
                <div className="relative_image_holder">
                    <a
                        className="image"
                        title={ this.PRODUCT.title }
                        onClick={ () => this.props.openProductModal(this.state.PRODUCT.id, this.PRODUCT) }
                        style={ STYLE }
                    >
                    </a>
                    <a
                        className="image"
                        title={ this.PRODUCT.title }
                        onClick={ () => this.props.openProductModal(this.state.PRODUCT.id, this.PRODUCT) }
                        style={ STYLE2 }
                    >
                    </a>
                    {/* { this.renderBadges() } */ }
                    { this.renderUpdatedBadges() }
                    <div className="pid_block">{ this.state.PRODUCT.id }</div>
                </div>
            );
        } else {
            let STYLE = { 'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)' };
            return (
                <div className="relative_image_holder">
                    <a onClick={ () => this.props.openProductModal(this.state.PRODUCT.id, this.PRODUCT) } title={ this.PRODUCT.title }
                        style={ STYLE }
                        className="image"
                    >
                    </a>
                    <a onClick={ () => this.props.openProductModal(this.state.PRODUCT.id, this.PRODUCT) } title={ this.PRODUCT.title }
                        style={ STYLE }
                        className="image"
                    >
                    </a>
                    {/* { this.renderBadges() } */ }
                    { this.renderUpdatedBadges() }
                    <div className="pid_block">{ this.state.PRODUCT.id }</div>
                </div>
            );
        }

    }

    renderUpdatedBadges = () => {
        let badges = []
        if (this.PRODUCT.hot == 1) {
            badges.push(
                <div key="hot" className="discount_percent hot">
                    ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾
                </div>
            )
        }

        if ((this.PRODUCT.sale == 1) && (this.PRODUCT.old_price > 0)) {
            let PERCENT = (100 - (this.PRODUCT.price / (this.PRODUCT.old_price / 100))).toFixed();
            badges.push(
                <div key="discount" className="discount_percent">
                    -{ PERCENT }%
                </div>
            )
        }

        if (this.PRODUCT.new_product == 1) {
            badges.push(
                <div key="new" className="discount_percent new">
                    ÐÐ¾Ð²Ð¸Ð½ÐºÐ°
                </div>
            )
        }

        if (this.PRODUCT.exclusive == 1) {
            badges.push(
                <div key="trend" className="discount_percent trend">
                    Ð’ Ñ‚Ñ€ÐµÐ½Ð´Ðµ
                </div>
            )
        }



        if (badges.length) {
            return (
                <div className="badges_holder">
                    { badges }
                </div>
            )
        }
    }


    renderDiscountPercent = () => {


    }

    renderTitle() {
        return (
            <Link className="product-card-title" to={ '/' + this.PRODUCT.url } title={ this.PRODUCT.title }>
                { this.PRODUCT.title }
            </Link>
        );
    }

    renderShortDesc() {
        return false;
        if (this.PRODUCT.short_description !== '') {
            return (
                <div className="short_desc_holder">
                    { this.PRODUCT.short_description }
                </div>
            );
        }
    }

    renderAmountCircle() {
        if (this.props.AMOUNT > 0) {
            return (
                <div className="cart-amount-circle" onClick={ () => this.launchManipulation() }>
                    { this.renderShowAmount() }
                </div>
            );
        }
    }

    renderPerAmount = () => {
        return (
            <div className="per_amount_card">
                { this.state.PRODUCT.peramount } { this.state.PRODUCT.ed_izm }
            </div>
        );
    }

    renderAmountBlock = () => {

        return (
            <div className="product_amount_block">
                { this.PRODUCT.price_per_one } Ñ€ / 1  { this.state.PRODUCT.ed_izm }
            </div>
        )
    }

    render() {
        return (
            <div className="product-col" ref={ this.setWrapperRef }>

                { this.renderImage() }


                <div className="card_data_block">
                    <div>
                        { this.renderTitle() }

                        { this.renderAmountBlock() }
                    </div>
                    <div className="spacer"></div>

                    { this.renderButtons() }
                </div>

            </div>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCard)
