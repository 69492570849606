import Cookies from 'universal-cookie';

import CONFIG from './../SYSTEM/Config.js'
import { sendPostRequest } from "./../APP/HELPERS/requests"

var initState = {
	TOKEN: false,
	USER: false,
	CATS: false,
	PRODUCTS: false,
	SLIDES: false,
	GROUPED_PRODUCTS: false,
	CART: false,
	POPULAR: false,
	DISCOUNTS: false,
	NEWPRODUCTS: false,
	EXCLUSIVE: false,
	PREPARED_CATS: false,

	PRODUCT_MODAL: false,
	LK_MODAL: false,
	CART_MODAL: false,
	CARD_MODAL: false,
	PAY_MODAL: false,
	DO_SEARCH: false,
	ORDER_MODAL: false,
	EMPTY_CART_MODAL: false,
	SHOW_CAT: false,

	TOP_NAVS: false,
	SHOW_SEARCH: false,
	LOADED_ORDER: false,

	SHOW_NAVS: false,
	FRIENDS_DISCOUNT: false,

	PRICE_TYPE: false
};

const cookies = new Cookies();

var reducer = function (state = initState, action) {
	let CART = {};
	let PRODUCT = false;

	switch (action.type) {
		case 'PRICE_TYPE':
			return {
				...state,
				PRICE_TYPE: action.data
			}
		case 'SET_FRIENDS_DISCOUNT':
			return {
				...state,
				FRIENDS_DISCOUNT: action.data
			}
		case 'SHOW_NAVS':
			return {
				...state,
				SHOW_NAVS: action.data
			}
		case 'SHOW_CAT':
			return {
				...state,
				SHOW_CAT: action.data
			}
		case 'LOAD_ORDER':
			return {
				...state,
				LOADED_ORDER: action.data
			}

		case 'SET_START_UP_DATA':
			let DATA = action.data;

			return {
				...state,
				TOKEN: DATA.TOKEN,
				CATS: DATA.CATS,
				PRODUCTS: DATA.PRODUCTS,
				SLIDES: DATA.SLIDES,
				GROUPED_PRODUCTS: DATA.GROUPED_PRODUCTS,
				POPULAR: DATA.POPULAR,
				DISCOUNTS: DATA.DISCOUNTS,
				NEWPRODUCTS: DATA.NEWPRODUCTS,
				EXCLUSIVE: DATA.EXCLUSIVE
			}

		case 'SET_GROUPED':
			return {
				...state,
				GROUPED_PRODUCTS: action.data
			}

		case 'SET_PREPARED_CATS':
			return {
				...state,
				PREPARED_CATS: action.data
			}

		case 'SET_CART':
			CART = action.data;
			return {
				...state,
				CART: CART
			}

		/// EMPTY CART ///
		case 'EMPTY_CART':
			CART = {};
			localStorage.setItem('CART', JSON.stringify(CART));

			return {
				...state,
				CART: CART
			}

		/// ADDING +1 TO PRODUCT IN CART ////
		case 'PLUS_CART':
			CART = Object.assign({}, state.CART);
			PRODUCT = action.data;
			CART[PRODUCT.id]++;
			localStorage.setItem('CART', JSON.stringify(CART));

			window.dataLayer.push({
				"ecommerce": {
					"add": {
						"products": [
							{
								"id": PRODUCT.id,
								"name": PRODUCT.title,
								"price": PRODUCT.price,
								"quantity": CART[PRODUCT.id]
							}
						]
					}
				}
			});

			return {
				...state,
				LAST_UPDATED: PRODUCT.id,
				CART: CART
			}

		/// ADDING TO CART ////
		case 'ADD_TO_CART':
			if (state.CART === false) {
				CART = Object.assign({});
			} else {
				CART = Object.assign({}, state.CART);
			}
			PRODUCT = action.data;
			CART[PRODUCT.id] = 1;

			///window.ym(88250055,'reachGoal','addToCart')

			window.dataLayer.push({
				"ecommerce": {
					"add": {
						"products": [
							{
								"id": PRODUCT.id,
								"name": PRODUCT.title,
								"price": PRODUCT.price,
								"quantity": 1
							}
						]
					}
				}
			});

			if (typeof window.yaCounter71028040 !== "undefined") {
				window.yaCounter71028040.reachGoal('ADD_TO_CART');
			}

			localStorage.setItem('CART', JSON.stringify(CART));

			return {
				...state,
				LAST_UPDATED: PRODUCT.id,
				CART: CART
			}

		/// ADDING TO CART ////
		case 'ADD_TO_CART_AMOUNT':
			if (state.CART === false) {
				CART = Object.assign({});
			} else {
				CART = Object.assign({}, state.CART);
			}
			PRODUCT = action.data;
			CART[PRODUCT.id] = action.amount;
			localStorage.setItem('CART', JSON.stringify(CART));

			return {
				...state,
				LAST_UPDATED: PRODUCT.id,
				CART: CART
			}

		/// REMOVING CART ////
		case 'MINUS_CART':
			CART = Object.assign({}, state.CART);
			PRODUCT = action.data;
			if (typeof CART[PRODUCT.id] !== "undefined") {

				let SET_REMOVE_CART = 0;

				if (CART[PRODUCT.id] === 1) {
					delete CART[PRODUCT.id];
				} else {
					CART[PRODUCT.id]--;
					SET_REMOVE_CART = CART[PRODUCT.id];
				}
				localStorage.setItem('CART', JSON.stringify(CART));

				window.dataLayer.push({
					"ecommerce": {
						"remove": {
							"products": [
								{
									"id": PRODUCT.id,
									"name": PRODUCT.title,
									"price": PRODUCT.price,
									"quantity": SET_REMOVE_CART
								}
							]
						}
					}
				});
			}

			return {
				...state,
				LAST_UPDATED: PRODUCT.id,
				CART: CART
			}

		/// REMOVING CART ////
		case 'REMOVE_FROM_CART':
			CART = Object.assign({}, state.CART);
			PRODUCT = action.data;
			if (typeof CART[PRODUCT.id] !== "undefined") {
				delete CART[PRODUCT.id];
				localStorage.setItem('CART', JSON.stringify(CART));

				window.dataLayer.push({
					"ecommerce": {
						"remove": {
							"products": [
								{
									"id": PRODUCT.id,
									"name": PRODUCT.title,
									"price": PRODUCT.price,
									"quantity": 0
								}
							]
						}
					}
				});
			}
			return {
				...state,
				LAST_UPDATED: PRODUCT.id,
				CART: CART
			}

		case 'OPEN_PRODUCT_MODAL':
			let PRODUCT_MODAL = action.data;
			return {
				...state,
				PRODUCT_MODAL: PRODUCT_MODAL
			}

		case 'ADD_CARD':
			return {
				...state,
				CARD_MODAL: action.data
			}

		case 'OPEN_LK':
			let OPEN_LK = action.data;
			return {
				...state,
				LK_MODAL: OPEN_LK
			}

		case 'CLOSE_ALL_MODALS':
			let CLOSE = false;
			return {
				...state,
				CART_MODAL: CLOSE,
				LK_MODAL: CLOSE,
				PRODUCT_MODAL: CLOSE,
				PAY_MODAL: CLOSE,
				SHOW_NAVS: CLOSE
			}

		case 'DO_PAY':
			return {
				...state,
				PAY_MODAL: action.data
			}

		case 'OPEN_CART':
			return {
				...state,
				CART_MODAL: action.data
			}

		case 'EMPTY_CART_MODAL':
			return {
				...state,
				EMPTY_CART_MODAL: action.data
			}

		case 'OPEN_ORDER':
			return {
				...state,
				ORDER_MODAL: action.data
			}

		case 'DO_AUTH':
			let USER = action.data;
			cookies.set('foodsi', USER.foodsi, { path: '/', time: USER.time });
			localStorage.setItem('USER', JSON.stringify(USER));
			return {
				...state,
				USER: USER
			}

		case 'UPDATE_AVATAR':
			let NEW_AVA_USER = Object.assign({}, state.USER);
			NEW_AVA_USER.IMAGE = action.data;
			cookies.set('foodsi', NEW_AVA_USER.foodsi, { path: '/', time: NEW_AVA_USER.time });
			localStorage.setItem('USER', JSON.stringify(NEW_AVA_USER));

			return {
				...state,
				USER: NEW_AVA_USER
			}

		case 'SET_BONUSES_AMOUNT':
			let NEW_USER = Object.assign({}, state.USER);
			NEW_USER.BONUSES = action.data;
			cookies.set('foodsi', NEW_USER.foodsi, { path: '/', time: NEW_USER.time });
			localStorage.setItem('USER', JSON.stringify(NEW_USER));

			return {
				...state,
				USER: NEW_USER
			}

		case 'DO_LOG_OUT':
			localStorage.removeItem('USER');
			cookies.set('foodsi', false, { path: '/', time: -1 });
			return {
				...state,
				USER: false
			}

		case 'DO_SEARCH':
			return {
				...state,
				DO_SEARCH: action.data
			}

		case 'CLOSE_NAV':
			return {
				...state,
				TOP_NAVS: false
			}

		case 'TOGGLE_NAV':
			let SET_NAVS = true;
			let SHOW_SHOW_SEARCH = false;
			if (state.TOP_NAVS === true) {
				SET_NAVS = false;
			}

			return {
				...state,
				TOP_NAVS: SET_NAVS,
				SHOW_SEARCH: SHOW_SHOW_SEARCH
			}

		case 'TOGGLE_SEARCH':
			let SHOW_SEARCH = true;
			let SHOW_TOP_NAVS = false;
			if (state.SHOW_SEARCH === true) {
				SHOW_SEARCH = false;
			}

			return {
				...state,
				SHOW_SEARCH: SHOW_SEARCH,
				TOP_NAVS: SHOW_TOP_NAVS
			}

		default:
			return state;
	}
}

export default reducer;