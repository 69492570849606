import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import { CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import CONFIG from './../../SYSTEM/Config.js'


function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        PRODUCTS: state.PRODUCTS,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        DO_SEARCH: state.DO_SEARCH,
        POPULAR: state.POPULAR,
        NEWPRODUCTS: state.NEWPRODUCTS,
        DISCOUNTS: state.DISCOUNTS,
        EXCLUSIVE: state.EXCLUSIVE
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Catalogue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            URL: props.url,
            CAT: false,
            ERROR: false,
            PRODUCTS: false
        }

        this.interval = false;
        this.WITH_CHILDREN = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.url !== prevProps.url) {
            this.props.closeAllModals();

            if (this.props.CATS) {
                this.setState({ CAT: false, PRODUCTS: false, URL: this.props.url, ERROR: false });
                let COMPONENT = this;
                this.interval = setInterval(function () {
                    COMPONENT.prepareData()
                }, 50);
            }
        }
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
    }

    componentDidMount() {
        this.props.closeAllModals();

        let COMPONENT = this;
        this.interval = setInterval(function () {
            COMPONENT.prepareData()
        }, 50);
    }

    getCat = () => {
        let CAT = false;
        let NEXT = false;
        let SET_J = false;
        let SET_I = false;

        if (this.props.url == "popular") {
            CAT = {
                title: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾Ðµ',
                children: false,
                id: 0,
                url: 'popular',
                parent: {},
                meta_title: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾Ðµ',
                meta_keywords: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾Ðµ',
                meta_description: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾Ðµ'
            };

        } else if (this.props.url == "trends") {
            CAT = {
                title: 'Ð’ Ñ‚Ñ€ÐµÐ½Ð´Ðµ',
                children: false,
                id: -2,
                url: 'trends',
                parent: {},
                meta_title: 'Ð’ Ñ‚Ñ€ÐµÐ½Ð´Ðµ',
                meta_keywords: 'Ð’ Ñ‚Ñ€ÐµÐ½Ð´Ðµ',
                meta_description: 'Ð’ Ñ‚Ñ€ÐµÐ½Ð´Ðµ'
            };
        } else if (this.props.url == "discounts") {
            CAT = {
                title: 'ÐÐºÑ†Ð¸Ð¸',
                children: false,
                id: -3,
                url: 'discounts',
                parent: {},
                meta_title: 'ÐÐºÑ†Ð¸Ð¸',
                meta_keywords: 'ÐÐºÑ†Ð¸Ð¸',
                meta_description: 'ÐÐºÑ†Ð¸Ð¸'
            };
        } else if (this.props.url == "new_products") {
            CAT = {
                title: 'ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸',
                children: false,
                id: -1,
                url: 'new_products',
                parent: {},
                meta_title: 'ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸',
                meta_keywords: 'ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸',
                meta_description: 'ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸'
            };

        } else {
            if (this.props.CATS) {
                for (let i = 0; i < this.props.CATS.length; i++) {
                    if ((!CAT) || (SET_I + 1 === i)) {

                        let PARENT_CAT = this.props.CATS[i];
                        if (PARENT_CAT.url === this.state.URL) {
                            CAT = PARENT_CAT;
                        }

                        if ((!CAT) || (SET_I + 1 === i)) {

                            if (PARENT_CAT.children.length) {
                                for (let j = 0; j < PARENT_CAT.children.length; j++) {
                                    let CHILD_CAT = PARENT_CAT.children[j];

                                    if (!NEXT) {

                                        if (SET_I !== false) {
                                            if (SET_I + 1 === i) {
                                                if (j === 0) {
                                                    NEXT = CHILD_CAT;
                                                }
                                            }
                                            if (SET_I === i) {
                                                if (SET_J + 1 === j) {
                                                    NEXT = CHILD_CAT;
                                                }
                                            }
                                        }

                                    }

                                    if (CHILD_CAT.url === this.state.URL) {
                                        CAT = CHILD_CAT;

                                        SET_J = j;
                                        SET_I = i;
                                    }
                                }
                            }


                        }

                        if (!NEXT) {
                            if (CAT) {
                                if (typeof this.props.CATS[i + 1] !== "undefined") {
                                    if (this.props.CATS[i + 1].children.length) {
                                        NEXT = this.props.CATS[i + 1].children[0];
                                    } else {
                                        NEXT = this.props.CATS[i + 1];
                                    }
                                } else {
                                    if (this.props.CATS[0].children.length) {
                                        NEXT = this.props.CATS[0].children[0];
                                    } else {
                                        NEXT = this.props.CATS[0];
                                    }
                                }
                            }
                        }

                    }
                }
            }
            if (CAT) {
                CAT.NEXT = NEXT;
                if (CAT.children.length) {
                    CAT.NEXT = CAT.children[0]
                }
            }

            if (typeof window.rrApiOnReady !== "undefined") {
                if (typeof window.rrApi !== "undefined") {
                    try {
                        window.rrApi.categoryView(CAT.id);
                    } catch (e) { }
                }
            }
        }

        if (CAT) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'page_category',
                'category_id': CAT.id,
                'category_name': CAT.title,
            });
            this.setState({ CAT: CAT, ERROR: false });
        } else {
            clearInterval(this.interval);
            this.setState({ CAT: 'NONE', PRODUCTS: true, ERROR: true });
        }
    }

    prepareData = async () => {
        if (!this.state.CAT) {
            if (this.props.CATS) {
                this.getCat();
            }
        }

        if (this.state.CAT) {
            if (!this.state.PRODUCTS) {
                let PRODUCTS = this.getProducts(this.state.CAT);
                this.setState({ PRODUCTS: PRODUCTS });
                clearInterval(this.interval);
            }
        }
    }

    onlyUnique = (value, index, array) => {
        return array.indexOf(value) === index;
    }

    getProducts(CAT) {
        let GROUPED_PRODUCTS = this.props.GROUPED_PRODUCTS;

        if ((CAT.children) && (CAT.children.length)) {

            let USE_PRODUCTS = false;
            let SUB_CATS = []
            this.setState({ WITH_CHILDREN: false });

            if (CAT.id > 0) {
                USE_PRODUCTS = [];
                for (let i = 0; i < CAT.children.length; i++) {
                    USE_PRODUCTS = USE_PRODUCTS.concat(GROUPED_PRODUCTS[CAT.children[i].id])
                    SUB_CATS.push(
                        <Link key={ i } to={ "/" + CAT.children[i].url }>
                            { CAT.children[i].title }
                        </Link>
                    )
                }
                USE_PRODUCTS = USE_PRODUCTS.filter(this.onlyUnique);
            } else {
                if (CAT.id == 0) {
                    if (typeof this.props.POPULAR !== "undefined") {
                        USE_PRODUCTS = this.props.POPULAR;
                    }
                } else if (CAT.id == -2) {
                    if (typeof this.props.EXCLUSIVE !== "undefined") {
                        USE_PRODUCTS = this.props.EXCLUSIVE;
                    }
                } else if (CAT.id == -1) {
                    if (typeof this.props.NEWPRODUCTS !== "undefined") {
                        USE_PRODUCTS = this.props.NEWPRODUCTS;
                    }
                } else if (CAT.id == -3) {
                    if (typeof this.props.DISCOUNTS !== "undefined") {
                        USE_PRODUCTS = this.props.DISCOUNTS;
                    }
                }
            }

            let CLASS = 'card_holder ' + this.getItemsClass();

            if (USE_PRODUCTS) {
                if (SUB_CATS.length) {
                    return (
                        <div className="cats_and_ps">
                            <div className="sub_cats_links_parent">
                                <div className="sub_cats_links">
                                    { SUB_CATS }
                                </div>
                            </div>
                            <div className="products_grid">
                                { USE_PRODUCTS.map(id => {
                                    if (typeof this.props.PRODUCTS[id] !== "undefined") {
                                        return <div className={ CLASS } key={ id }><ProductCard ID={ id } /></div>
                                    }
                                }) }
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="products_grid">
                            { USE_PRODUCTS.map(id => {
                                if (typeof this.props.PRODUCTS[id] !== "undefined") {
                                    return <div className={ CLASS } key={ id }><ProductCard ID={ id } /></div>
                                }
                            }) }
                        </div>
                    )
                }
            }

        } else {
            let USE_PRODUCTS = false;
            this.setState({ WITH_CHILDREN: false });

            if (CAT.id > 0) {
                if (typeof GROUPED_PRODUCTS[CAT.id] !== "undefined") {
                    USE_PRODUCTS = GROUPED_PRODUCTS[CAT.id];
                }
            } else {
                if (CAT.id == 0) {
                    if (typeof this.props.POPULAR !== "undefined") {
                        USE_PRODUCTS = this.props.POPULAR;
                    }
                } else if (CAT.id == -2) {
                    if (typeof this.props.EXCLUSIVE !== "undefined") {
                        USE_PRODUCTS = this.props.EXCLUSIVE;
                    }
                } else if (CAT.id == -1) {
                    if (typeof this.props.NEWPRODUCTS !== "undefined") {
                        USE_PRODUCTS = this.props.NEWPRODUCTS;
                    }
                } else if (CAT.id == -3) {
                    if (typeof this.props.DISCOUNTS !== "undefined") {
                        USE_PRODUCTS = this.props.DISCOUNTS;
                    }
                }
            }

            let CLASS = 'card_holder ' + this.getItemsClass();

            if (USE_PRODUCTS) {
                return (
                    <div className="products_grid">
                        { USE_PRODUCTS.map(id => {
                            if (typeof this.props.PRODUCTS[id] !== "undefined") {
                                return <div className={ CLASS } key={ id }><ProductCard ID={ id } /></div>
                            }
                        }) }
                    </div>
                );
            }
        }

        return false;
    }

    getItemsClass() {
        return '';
    }

    renderH1() {
        return (
            <div className="row">
                <div className="col-12">
                    <h1 className="catalog__title">
                        { this.state.CAT.title }
                    </h1>
                </div>
            </div>
        );
    }

    renderBreadCrumbs() {
        let DATA = [];
        DATA.push({
            'to': '/',
            'title': 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ'
        });
        if (typeof this.state.CAT.parent.title !== "undefined") {
            DATA.push({
                'to': '/' + this.state.CAT.parent.url,
                'title': this.state.CAT.parent.title
            });
        }
        DATA.push({
            'to': '/' + this.state.CAT.url,
            'title': this.state.CAT.title
        });

        return <BreadCrumbs DATA={ DATA } />
    }

    renderNextCat() {

        if (this.state.CAT.NEXT) {

            return (
                <div className="main_more_holder">
                    <Link to={ '/' + this.state.CAT.NEXT.url } className="main_more cat_more">
                        <span>{ this.state.CAT.NEXT.title }</span><img src="/system_images/angle_right.svg" />
                    </Link>
                </div>
            );
        } else {
            console.log(this.state.CAT.id)
            if (this.state.CAT.id == -3) {
                return (
                    <div className="main_more_holder">
                        <Link to={ '/newproducts' } className="main_more cat_more">
                            <span>ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸</span><img src="/system_images/angle_right.svg" />
                        </Link>
                    </div>
                );
            } else if (this.state.CAT.id == -2) {
                return (
                    <div className="main_more_holder">
                        <Link to={ '/exclusive' } className="main_more cat_more">
                            <span>Ð­ÐºÑÐºÐ»ÑŽÐ·Ð¸Ð²</span><img src="/system_images/angle_right.svg" />
                        </Link>
                    </div>
                );
            } else if (this.state.CAT.id == -1) {
                return (
                    <div className="main_more_holder">
                        <Link to={ '/hleb-vyp-snek' } className="main_more cat_more">
                            <span>Ð¥Ð»ÐµÐ±</span><img src="/system_images/angle_right.svg" />
                        </Link>
                    </div>
                );
            }
        }
    }

    renderPrevCat() {
        return false;

        if (this.state.CAT.PREV) {
            let CAT = this.state.CAT.PREV;
            let CAT2 = this.state.CAT.NEXT;
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="cats-preview">
                            <div className="preview-col-6">
                                <Link to={ "/" + CAT.url } className="cat_preview big" style={ { backgroundImage: 'url(' + CONFIG.API_C_DOMAIN + '/imgs/groups/' + CAT.image + ')' } }>
                                    <div className="more-intro">
                                        ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ð² Ð¿Ñ€ÐµÐ´Ñ‹Ð´ÑƒÑ‰Ð¸Ð¹ Ñ€Ð°Ð·Ð´ÐµÐ»
                                    </div>
                                    <span>{ CAT.title }</span>
                                </Link>
                            </div>
                            <div className="preview-col-6">
                                <Link to={ "/" + CAT2.url } className="cat_preview big" style={ { backgroundImage: 'url(' + CONFIG.API_C_DOMAIN + '/imgs/groups/' + CAT2.image + ')' } }>
                                    <div className="more-intro">
                                        ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ð² ÑÐ»ÐµÐ´ÑƒÑŽÑ‰Ð¸Ð¹ Ñ€Ð°Ð·Ð´ÐµÐ»
                                    </div>
                                    <span>{ CAT2.title }</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderHiddenText() {
        if (this.state.CAT.after !== '') {
            return (
                <div>
                    <div dangerouslySetInnerHTML={ { __html: this.state.CAT.after } }>
                    </div>
                </div>
            );
        }
    }

    render() {
        if ((!this.state.CAT) && (!this.state.PRODUCTS)) {
            return <InnerLoader />
        }

        if (this.state.ERROR) {
            return <E404 />;
        }

        if (this.state.WITH_CHILDREN) {
            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part transparent">
                        <Helmet>
                            <title>{ this.state.CAT.meta_title.length ? this.state.CAT.meta_title : this.state.CAT.title }</title>
                            <meta name="description" content={ this.state.CAT.meta_description.length ? this.state.CAT.meta_description : this.state.CAT.title } />
                            <meta name="keywords" content={ this.state.CAT.meta_keywords.length ? this.state.CAT.meta_keywords : this.state.CAT.title } />
                        </Helmet>
                        <div id="page-content" className="container">
                            { this.renderBreadCrumbs() }
                            <div>
                                { this.renderH1() }
                            </div>

                            { this.state.PRODUCTS }


                            {/* { this.renderPrevCat() }
                            { this.renderNextCat() } */}
                        </div>


                        { this.renderHiddenText() }

                        <ModalsContainer />
                    </div>
                </CSSTransitionGroup>
            );
        } else {
            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part">
                        <Helmet>
                            <title>{ this.state.CAT.meta_title.length ? this.state.CAT.meta_title : this.state.CAT.title }</title>
                            <meta name="description" content={ this.state.CAT.meta_description.length ? this.state.CAT.meta_description : this.state.CAT.title } />
                            <meta name="keywords" content={ this.state.CAT.meta_keywords.length ? this.state.CAT.meta_keywords : this.state.CAT.title } />
                        </Helmet>
                        <div id="page-content" className="container">
                            { this.renderBreadCrumbs() }
                            <div>
                                { this.renderH1() }
                            </div>
                            { this.state.PRODUCTS }
                            {/* { this.renderNextCat() } */ }
                        </div>

                        <ModalsContainer />
                    </div>
                </CSSTransitionGroup>
            );
        }
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Catalogue))